import './App.css';
import ReactGA from 'react-ga4';
import { useEffect } from 'react';

function App() {
  ReactGA.initialize('G-KCGSJEFWWN');

  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: window.location.pathname });
  }, []);

  const handleClick = (action) => {
    ReactGA.event({
      category: 'User',
      action: action,
    });
  };

  return (
    <div className="App">
      <div className="block">
        <h1>Font Replacer Ultimate</h1>
        <h2>Best Figma plugin for font management so far.</h2>
        <div className="buttons">
          <a
            href="https://www.figma.com/community/plugin/1100808562040625391"
            target="_blank"
            onClick={handleClick('Click community plugin page')}
          >
            <button className="button-84">Try plugin</button>
          </a>
          <a
            href="https://kolebaev.lemonsqueezy.com/buy/7e3dc4eb-c6d1-4803-8cb9-98de300233b9"
            target="_blank"
            onClick={handleClick('Click lemon squeezy store')}
          >
            <button className="button-84">Get license key</button>
          </a>
        </div>

        <img src="/image.png" />
      </div>
    </div>
  );
}

export default App;
